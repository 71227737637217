import {getPlayers} from '@/api/players';
import {getHoles} from '@/api/holes';

export function getScoreText() {
    const players = getPlayers();
    const holes = getHoles();

    for (const hole of holes) {
        if (players.length !== hole.scores.length) {
            return 'interimScore';
        }
    }

    return 'finalScore';
}