<template>
  <div>
    <div v-if="players.length">
      <div v-for="player in players" :key="player.id">
        <Player @delete-player="$emit('delete-player', player.id)" :player="player" />
      </div>
    </div>

    <p v-else style="font-style: italic;">Nog geen spelers opgegeven</p>
  </div>
</template>

<script>
import Player from './Player'

export default {
  name: 'Players',
  props: {
    players: Array
  },
  components: {
      Player,
  }
}
</script>