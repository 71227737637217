<template>
  <form @submit="onSubmit" class="mb-5 bg-gray-200 p-4 rounded-md">
    <div class="mb-5">
      <label for="name" class="block">{{ $t('name') }}</label>
      <input type="text" v-model="name" id="name" name="name" :placeholder="$t('name')" class="w-full h-10 mt-1 py-2 px-4 border-2">
    </div>

    <div class="flex justify-center">
      <Button type="submit" :text="$t('addPlayer')" />
    </div>
  </form>
</template>

<script>
import Button from '../Button'
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'AddPlayer',

  components: {
    Button,
  },

  data() {
    return {
      name: '',
    }
  },

  methods: {
    onSubmit(e) {
      e.preventDefault()

      if (!this.name) {
        alert(this.$t('noNameAlert'))
        return
      }

      const player = {
        id: uuidv4(),
        name: this.name
      }

      this.$emit('add-player', player)

      this.name = ''
    }
  }
}
</script>