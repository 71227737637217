<template>
  <div class="mb-5">
    <header class="grid grid-cols-1 gap-4 mb-5">
        <img src="../assets/img/logo.jpg" alt="Logo" />
        <h1>{{ $t('title') }}</h1>
    </header>
    <hr>
  </div>
</template>

<script>
export default {
	name: 'Header',
  props: {
    title: String,
  },
}
</script>