<template>
  <button @click="$emit('btn-click')" :class="buttonColor" class="text-white py-2 px-4 rounded-md focus:outline-none transform active:scale-95" :type="type">{{ text }}</button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: String,
    color: String,
    type: String,
  },
  computed: {
    buttonColor: function () {
      const color = (this.color) ? this.color : 'button'

      return 'bg-' + color
    }
  }
}
</script>