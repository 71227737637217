<template>
  <div class="flex justify-between items-center bg-gray-200 mb-2 py-3 px-4">
    <h3>{{ player.name }}</h3>
    <i @click="$emit('delete-player')" class="fas fa-times text-red-500 cursor-pointer"></i>
  </div>
</template>

<script>
export default {
  name: 'Player',
  props: {
    player: Object
  },
}
</script>
