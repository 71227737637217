<template>
  <div>
    <div v-for="(finalScore, index) in finalScores" :key="finalScore.player.id" class="flex justify-center text-2xl">

      <div v-if="index === 0">
        <div>🥇 {{finalScore.player.name}} ({{ finalScore.finalScore }})</div>
      </div>
      <div v-else-if="index === 1">
        <div>🥈 {{finalScore.player.name}} ({{ finalScore.finalScore }})</div>
      </div>
      <div v-else-if="index === 2">
        <div class="mb-8">🥉 {{finalScore.player.name}} ({{ finalScore.finalScore }})</div>
      </div>
      <div v-else>
        <div>#{{ index + 1 }}: {{finalScore.player.name}} ({{ finalScore.finalScore }})</div>
      </div>
      
    </div>

    <div class="grid grid-cols-1 gap-2 mt-8">
      <Button v-if="showBackToHole" @btn-click="$router.back()" :text="$t('backToHole')"/>
      <Button @btn-click="$router.push('/game')" :text="$t('backToOverview')"/>
    </div>
  </div>
</template>

<script>
import Button from '../Button'

import {getPlayers} from '@/api/players'
import {getHoles} from '@/api/holes'

export default {
  name: 'FinalScore',

  components: {
    Button,
  },

  data() {
    return {
      showBackToHole: true,
    }
  },

  computed: {
    finalScores: function() {
      const holes = getHoles();
      const players = getPlayers();

      let finalScores = [];

      players.forEach(function(player) {

        let total = 0;

        // Get score for player per hole
        holes.forEach(function(hole) {
          const score = hole.scores.filter(s => s.playerId === player.id)[0];

          if (score) {
            total += parseInt(score.score);
          }
        })

        const finalScore = {
          player: player,
          finalScore: total
        }

        finalScores.push(finalScore);
      })

      // Sort array in ascending order based on score
      finalScores.sort(function(a, b) {
        return a.finalScore - b.finalScore;
      });

      return finalScores;
    }
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.showBackToHole = from.name === 'hole';
    })
  }
}
</script>