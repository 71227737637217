<template>
  <div class="flex justify-between items-center bg-gray-200 my-2 py-3 px-4">
    <h3>{{ player.name }}</h3>

    <input v-model="score" type="tel" class="text-center w-10 h-10 rounded-md">
  </div>
</template>

<script>
import {addScore} from '@/api/holes'

export default {
  name: 'Score',

  props: {
    hole: Object,
    player: Object
  },

  // Since hole dynamically changes, score needs to be computed
  computed: {
    score: {
      get: function() {
        const index = this.hole.scores.findIndex(s => s.playerId === this.player.id);

        // Player already has a score
        if (index !== -1) {
          return this.hole.scores[index].score
        }

        return '';
      },

      set: function(newValue) {
        if (newValue) {
          addScore(this.hole, this.player, newValue);
        }
      }
    }
  
  }
}
</script>
