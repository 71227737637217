<template>
  <div>
    <div class="text-2xl font-bold mb-5">Hole {{ hole.hole }}</div>

    <Score v-for="player in players" :key="player.id" :hole="hole" :player="player" />

    <div class="mt-5 grid grid-cols-1 gap-2">
      <Button v-if="hole.hole !== 18" @btn-click="$router.push({ name: 'hole', params: { holeId: hole.hole + 1 }})" :text="$t('next') + ' >'" />

      <Button @btn-click="$router.push({ name: 'finalScore' })" :text="$t(this.scoreText)" />

      <Button @btn-click="$router.push('/game')" :text="$t('backToOverview')"/>

      <Button v-if="hole.hole !== 1" @btn-click="$router.push({ name: 'hole', params: { holeId: hole.hole - 1 }})" :text="'< ' + $t('previous')" />
    </div>

    <div v-if="imageUrl">
      <hr class="my-6">

      <img :src="imageUrl" class="rounded-2xl" alt="hole-image">
    </div>
  </div>
</template>

<script>
import Score from './Score';
import Button from '../Button';

import {getPlayers} from '@/api/players';
import {getHole} from '@/api/holes';
import {getScoreText} from "@/api/score";

export default {
  name: 'Hole',

  components: {
    Score,
    Button
  },

  data() {
    return {
      hole: Object,
      players: [],
      scoreText: '',
    }
  },

  props: {
    holeId: Number,
  },

  computed: {
    imageUrl: function () {
      try {
        return require('@/assets/img/holes/hole-' + this.holeId + '.jpg');
      } catch (_) {
        return null;
      }
    }
  },

  watch: {
    // Component instance gets reused, hence on change, get new hole
    $route(to) {
      this.hole = getHole(to.params.holeId);
    }
  },

  mounted() {
    this.players = getPlayers();
    this.hole = getHole(this.holeId);
    this.scoreText = getScoreText();
  }
}
</script>