<template>
  <div>
    <div class="flex justify-between items-center mb-5">
      <h3 class="font-bold">{{ $t('players') }}:</h3>
      <Button @btn-click="toggleAddPlayer" :text="showAddPlayer ? $t('close') : $t('addPlayer')" :color="showAddPlayer ? 'red-500' : ''" />
    </div>

    <div v-show="showAddPlayer">
      <AddPlayer @add-player="addPlayer" />
    </div>

    <Players @delete-player="deletePlayer" :players="players" />

    <div class="flex justify-center mt-5">
      <Button v-if="players.length > 1" @btn-click="$router.push('game')" :text="$t('startGame')" />
    </div>
  </div>
</template>

<script>
import AddPlayer from './AddPlayer'
import Button from '../Button'
import Players from './Players'

import {getPlayers, setPlayers} from '@/api/players'
import {deletePlayer} from '@/api/holes'

export default {
  name: 'PlayerManagement',
  
  components: {
    AddPlayer,
    Button,
    Players,
  },

  data() {
    return {
      players: [],
      showAddPlayer: false,
    }
  },

  methods: {
    toggleAddPlayer() {
      this.showAddPlayer = !this.showAddPlayer;
    },

    addPlayer(player) {
      this.players = [...this.players, player];
    },
    
    deletePlayer(id) {
      if (confirm(this.$t('removePlayerConfirm'))) {
        this.players = this.players.filter(player => player.id !== id);
        deletePlayer(id);
      }
    },
  },

  watch: {
    players: function (val) {
      setPlayers(val)
    }
  },

  mounted() {
    this.players = getPlayers()
  }
}
</script>
