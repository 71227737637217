<template>
  <div>
    <div class="grid grid-cols-3 gap-4">
      <Button v-for="hole in holes" :key="hole.hole"
        @btn-click="$router.push({ name: 'hole', params: { holeId: hole.hole }})" 
        :color="(players.length !== hole.scores.length) ? 'gray-500' : 'green-500'" 
        class="flex justify-center font-bold text-xl p-4 transition duration-300 hover:scale-95"
        :text="'Hole ' + hole.hole" />
    </div>

    <div class="mt-8 flex justify-around">
      <Button
        @btn-click="$router.push({ name: 'players' })" 
        :text="$t('players')" />

      <Button
        @btn-click="$router.push({ name: 'finalScore' })" 
        :text="$t(this.scoreText)" />
    </div>
  </div>
</template>

<script>
import Button from '../Button'

import {getPlayers} from '@/api/players'
import {getHoles, setHoles} from '@/api/holes'
import {getScoreText} from "@/api/score";

export default {
  name: 'GameOverview',

  components: {
    Button,
  },

  data() {
    return {
      players: [],
      holes: [],
      scoreText: '',
    }
  },

  watch: {
    holes: function (val) {
      setHoles(val);
    }
  },

  mounted() {
    this.players = getPlayers();
    this.holes = getHoles();
    this.scoreText = getScoreText();
  }
}
</script>
