export function getPlayers() {
  const players = JSON.parse(localStorage.getItem('players'));

  if (players) {
    return players;
  }

  return [];
}

export function setPlayers(val) {
  localStorage.setItem('players', JSON.stringify(val));
}
