export function getHoles() {
  let holes = JSON.parse(localStorage.getItem('holes'));

  if (holes) {
    return holes;
  }

  holes = [];

  for (let i = 1; i <= 18; i++) {
    const hole = {
      hole: i,
      scores: []
    }
    
    holes.push(hole);
  }

  return holes;
}

export function getHole(hole) {
  return getHoles().filter(h => h.hole == hole)[0]
}

export function setHoles(val) {
  localStorage.setItem('holes', JSON.stringify(val));
}

export function addScore(hole, player, score) {
  let holes = getHoles();

  const newScore = {
    playerId: player.id,
    score: score
  }

  const newHoles = holes.map(function (h) {
    if (h.hole == hole.hole) {
      // See if player already has a score
      const index = h.scores.findIndex(s => s.playerId === player.id);

      // Player already has a score
      if (index !== -1) {
        h.scores[index] = newScore
      
      // New score
      } else {
        h.scores.push(newScore)
      }
    }

    return h
  })

  setHoles(newHoles)
}

export function deletePlayer(playerId) {
  let holes = getHoles();

  const newHoles = holes.map(function (h) {
    h.scores = h.scores.filter(s => s.playerId != playerId)
    return h
  })
  
  setHoles(newHoles)
}
