<template>
  <div>
    <div class="max-w-xl mx-auto">
      <div class="m-3 p-8 pt-3 border rounded-md">
        <Header class="font-serif text-3xl font-bold text-center" />

        <router-view></router-view>
      </div>
    </div>
  </div>
  
</template>

<style src="./assets/styles/index.css"></style>

<script>
import Header from './components/Header'

export default {
  name: 'App',
  components: {
    Header,
  },
  created () {
    document.title = this.$t('title');
  }
}
</script>