import Vue from 'vue'
import VueRouter from 'vue-router'

import PlayerManagement from '../components/players/PlayerManagement'
import GameOverview from '../components/game/GameOverview'
import Hole from '../components/game/Hole'
import FinalScore from '../components/game/FinalScore'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'players',
    component: PlayerManagement
  },
  {
    path: '/game',
    component: GameOverview
  },
  {
    path: '/hole/:holeId',
    name: 'hole',
    component: Hole,
    props: ({params}) => ({holeId: Number.parseInt(params.holeId, 10) || 0})
  },
  {
    path: '/final-score',
    name: 'finalScore',
    component: FinalScore
  }
]

const router = new VueRouter({
  routes
})

export default router
